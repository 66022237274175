.dashboard{
    height: 100%;
    width: 100%;
    background-color: #1A1B1F;
}

.dashboardTopBar{
    height: 6%;
    width: 100%;
    background-color: #0C0C0E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}

.topBarLogo{
    height: 24px;
    width: 76.25px;
}

.dashboardContent{
    height: 86%;
    width: 100%;
}

.dashboardTabbar{
    background-color: #0C0C0E;
    height: 8%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.dashboardTabBarItem{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dashboardTabBarItemIcon{
    width: 90%;
    height: 90%;
}
.dashboardTabBarItemIconContainer{
    // width: 30px;
    // height: 28px;
    width: 35%;
    height: 45%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dashboardTabBarItemText{
    margin: 0px;
    color: #CFCFCF;
    font-size: 13px;
    font-family: 'Inter';
    font-weight: 400;
    word-wrap: break-word
}

.dashboardTabBarItemActive{
    .dashboardTabBarItemIcon{
        color: #FF6DB0;
    }
    .dashboardTabBarItemText{
        color: #FF6DB0;
    }
    .dashboardTabBarItemIconContainer{
        background-color: #FF39941F;
    }
}