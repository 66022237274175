.btn{
    background-color: #6434BB;
    color: white;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
    width: 80%;
    height: 60px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}