.backButton{
    border: 0.50px solid #2C2D33 ;
    background-color: #1A1B1F;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.56);
    border-radius: 8px;
    justify-content: flex-start; 
    align-items: center; 
    gap: 8px; 
    display: flex;
    padding-left: 16px; 
    padding-right: 16px; 
    padding-top: 8px; 
    padding-bottom: 8px;

    color: white;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 400;
    word-wrap: break-word;

    width: 102px;
    height: 40px;

    margin-left: 16px;
    margin-bottom: 16px;
}