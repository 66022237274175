.input-contain{
    position: relative;
    width: 90%;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;

}
input{
    height: 60px;
    width: 100%;
    border: 2px solid #6A6A6A;
    border-top: none;
    border-left: none;
    border-right: none;
    // background-color: #1A1B1F;
    color: white;
    font-family: 'Inter';
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
input:focus{
    outline: none;
    border-color: #8243D5;
  color: white;
  padding-top: 10px;
}
input:focus + .placeholder-text .text, :not(input[value=""]) + .placeholder-text .text{
    // background-color: #1A1B1F;
    font-size: 12px;
    color: white;
    transform: translate(-11%, -88%);
    font-family: 'Inter';
}
input:valid{
    padding-top: 10px;
}

input:valid + .placeholder-text .text, :not(input[value=""]) + .placeholder-text .text{
    // background-color: #1A1B1F;
    font-size: 12px;
    color: #6A6A6A;
    transform: translate(-11%, -88%);
    font-family: 'Inter';
}

input:focus + .placeholder-text .text{
    border-color: #8243D5;
    color: #8243D5;
    font-family: 'Inter';
}
.placeholder-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    align-items: center;
    font-family: 'Inter';
}
.text{
    font-size: 16px;
    padding: 0 0.5rem;
    background-color: transparent;
    transform: translate(0);
    color: #6A6A6A;
    transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
    font-family: 'Inter';
}
input, .placeholder-text{
    font-size: 16px;
    padding: 0 1.2rem;
}