.withdraw{
    height: 100%;
    width: 100%;
    background-color: #1A1B1F;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.withdrawCard{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.withdrawTable{
    width: 100%;

    .withdrawTableRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        border-top: 1px solid #1A1B1F;
        border-bottom: 1px solid #1A1B1F;
    }
}

