.signInPage{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20%;
}

.signInLogo{
    width: 74.22px;
    height: 64px;
}

.forgotPasswordText{
    color: white;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word
}
