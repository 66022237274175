.homeTab{
    width: 100%;
    height: 100%;
    background-color: #1A1B1F;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

.accessYourPayDiv{
    width: 100%;
    text-align: left;
    padding-left: 4%;
    border-bottom: 1px solid #1A1B1F;
    p{
        color: #F5F5F5;
        font-size: 16px;
        font-family: 'Inter';
        font-weight: 500;
        word-wrap: break-word
    }
    
}

.accessYourPayHr{
    width: 100%;
    color: #1A1B1F;
    background-color: #1A1B1F;
    height: 1px;
}

.balanceGuage {
    height: 10%;
    margin: auto;
    display: block;
    path {
      stroke-linecap: round;
      stroke-width: 6;
    }
    
    path.grey {
      stroke: #1E1A1C;
    }
    
    path.blue {
      stroke: #FF3994;
      stroke-dasharray: 130;
      // stroke-dashoffset: 0;
      animation: dash 3s ease-out forwards;
    }
  
  .withdrawTxtHead{
      fill: white;
      font-size: 60%;
      font-family: 'Inter';
      font-weight: 600;
      word-wrap: break-word
  }
  
  .withdrawTxtSub{
      fill: #9499AC;
      font-size: 30%;
      font-family: 'Inter';
      font-weight: 500;
      word-wrap: break-word
  }
  }
  
 

.withdrawalDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}